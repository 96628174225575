import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/user/login/login.component';
import { AuthGuard } from './_helpers/auth.guard';
import { Role } from './_models/role';
import { ForgotPasswordComponent } from './views/user/forgot-password/forgot-password.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'top-page',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: '500',
    component: P500Component,
    data: { title: 'Page 500' }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Login Page' }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: { title: 'Forgot password Page' }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'ホームページ'
    },
    children: [
      {
        path: 'top-page',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.MBS, Role.Maker, Role.Company, Role.Division, Role.Office, Role.Agency] }

      },
      {
        path: 'order',
        loadChildren: () => import('./views/order/order.module').then(m => m.OrderModule),
        data: { roles: [Role.MBS, Role.Maker, Role.Company, Role.Division, Role.Office, Role.Agency] }
      },
      {
        path: 'order-create',
        loadChildren: () => import('./views/order-create/order-create.module').then(m => m.OrderCreateModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.Office, Role.Agency] }
      },
      {
        path: 'property',
        loadChildren: () => import('./views/properties/properties.module').then(m => m.PropertiesModule),
        data: { roles: [Role.MBS, Role.Company, Role.Division, Role.Office, Role.Agency] }
      },
      {
        path: 'user-settings',
        loadChildren: () => import('./views/user/user-settings/user-settings.module').then(m => m.UserSettingsModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.MBS, Role.Maker, Role.Company, Role.Division, Role.Office, Role.Agency] }
      },
      {
        path: 'request-report',
        loadChildren: () => import('./views/request-report/request-report.module').then(m => m.RequestReportModule),
        data: { roles: [Role.Company, Role.Division, Role.Office, Role.Agency] }
      },
      {
        path: 'request-report-create',
        loadChildren: () => import('./views/request-report/request-report-create/request-report-create.module').then(m => m.RequestReportCreateModule),
        canActivate: [AuthGuard],
        data: { roles: [Role.Office] }
      },
      {
        path: '404',
        component: P404Component,
        data: { title: 'Page 404' }
      },
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
