import { INavData } from '@coreui/angular';
interface INavDataCRM extends INavData {
  role: string;
  checkSekisui: boolean;
}
export const navItems: INavDataCRM[] = [
  {
    name: 'トップページ',
    url: '/top-page',
    icon: 'icon-speedometer',
    role: 'MBS, MAKER, COMPANY, DIVISION, OFFICE, AGENCY',
    checkSekisui: false,
  },
  {
    name: '新規注文入力',
    url: '/order-create',
    icon: 'fa fa-pencil-square-o',
    role: 'OFFICE, AGENCY',
    checkSekisui: false,
  },
  {
    name: '注文検索',
    url: '/order/search',
    icon: 'fa fa-search',
    role: 'MBS, MAKER, COMPANY, DIVISION, OFFICE, AGENCY',
    checkSekisui: false,
  },
  {
    name: '物件検索',
    url: '/property',
    icon: 'fa fa-university',
    role: 'MBS, COMPANY, DIVISION, OFFICE, AGENCY',
    checkSekisui: false,
  },
  {
    name: 'ユーザー情報',
    url: '/user-settings',
    icon: 'fa fa-user-plus',
    role: 'MBS, MAKER, COMPANY, DIVISION, OFFICE, AGENCY',
    checkSekisui: false,
  },
  {
    name: '写真・特別設え入力',
    url: '/request-report-create',
    icon: 'fa fa-pencil-square-o',
    role: 'OFFICE',
    checkSekisui: true,
  },
  {
    name: '写真・特別設え検索',
    url: '/request-report/search',
    icon: 'fa fa-search',
    role: 'MBS, COMPANY, DIVISION, OFFICE, AGENCY',
    checkSekisui: true,
  },
];
